import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('#form-application').on('submit-success', function(event, data) {
    $('.page-result').removeClass('d-none');
    // $('.page-game').removeClass('d-none');
    $('.result').removeClass('d-none');

    $('html, body').animate({
        scrollTop: $("#application").offset().top
    }, 600);

    $(`.result-${data.prize ? 'success' : 'failed'}`).removeClass('d-none');
    if (data.prize) {
        $('.result-prize-name').html(data.prize.name);
        $('.result-form-link').attr('href', data.formUrl);
    }

    if (!data.guaranteedReward) {
        $('.guaranteed-rewards').remove();
    }

    setRegisteredData(data, 'section#application');

    $('#form-phone').append(`<input type="hidden" name="uuid" value="${data.uuid}">`);

    $('#modal-form-application').modal('show');

    if (typeof dataLayer !== 'undefined') {
        const prize = data.guaranteedReward === 'canal' ? 'Canal+' : data.guaranteedReward === 'basic-lab' ? 'BasicLab' : null;

        dataLayer.push({
            'event': 'registration',
            'prize' : '<' + prize + '>'
        })
    }

    // if (typeof dataLayer !== 'undefined') {
    //     $('body').append('' +
    //         '<!--' +
    //         'Fragment kodu zdarzenia (2023.11_Przelewy24_ClickButton_Wyślij, https://www.przelewy24.pl/): nie usuwać.' +
    //         'Umieść ten fragment kodu na stronach ze śledzonymi zdarzeniami.' +
    //         'Data utworzenia: 10/31/2023' +
    //         '-->' +
    //         '<script>' +
    //         '  gtag(\'event\', \'conversion\', {' +
    //         '    \'allow_custom_scripts\': true,' +
    //         '    \'u2\': \'[linkclick]\',' +
    //         '    \'u3\': \'[clicktext]\',' +
    //         '    \'send_to\': \'DC-13879267/1112023/202310+standard\'' +
    //         '  });' +
    //         '</script>' +
    //         '<noscript>' +
    //         '<img src="https://ad.doubleclick.net/ddm/activity/src=13879267;type=1112023;cat=202310;u2=[linkclick];u3=[clicktext];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[Random Number]?" width="1" height="1" alt=""/>' +
    //         '</noscript>' +
    //         '<!-- Koniec fragmentu kodu zdarzenia: nie usuwać -->' +
    //         '')
    //
    //     $('body').append('' +
    //         '<!--' +
    //         'Fragment kodu zdarzenia (2023.11_Przelewy24_TYP, https://www.przelewy24.pl/): nie usuwać.' +
    //         'Umieść ten fragment kodu na stronach ze śledzonymi zdarzeniami.' +
    //         'Data utworzenia: 10/31/2023' +
    //         '-->' +
    //         '<script>' +
    //         '  gtag(\'event\', \'conversion\', {' +
    //         '    \'allow_custom_scripts\': true,' +
    //         '    \'u1\': \'[pageurl]\',' +
    //         '    \'send_to\': \'DC-13879267/1112023/2023100+standard\'' +
    //         '  });' +
    //         '</script>' +
    //         '<noscript>' +
    //         '<img src="https://ad.doubleclick.net/ddm/activity/src=13879267;type=1112023;cat=2023100;u1=[pageurl];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=[Random Number]?" width="1" height="1" alt=""/>' +
    //         '</noscript>' +
    //         '<!-- Koniec fragmentu kodu zdarzenia: nie usuwać -->' +
    //         '')
    // }
    // $('body').append('' + '<script type="text/javascript" src="https://system3secure.pl/p.ashx?a=162&f=js&e=613&t=0"></script>' + '');
});

$('#form-phone').on('submit-success', function(event, data) {
    $(this).find('input[name="uuid"]').remove();

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            "event": "phone_notification"
        });
    }

    $('#modal-form-application').modal('hide');
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]').prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[extraData][street]"]',
        '[name="personal_data[extraData][streetNr]"]',
        '[name="personal_data[extraData][flatNr]"]',
        '[name="personal_data[extraData][zipCode]"]',
        '[name="personal_data[extraData][city]"]',
        '[name="personal_data[extraData][citizenship]"]',
    ];
    $(fields.join(', ')).prop('disabled', !$(this).prop('checked'))
    if (!$(this).prop('checked')) {
        $(fields.join(', ')).closest('.form-group').addClass('disabled');
        $('#no-pesel-header').addClass('d-none');
    } else {
        $(fields.join(', ')).closest('.form-group').removeClass('disabled');
        $('#no-pesel-header').removeClass('d-none');
    }
}).trigger('change');
$('#winner-form').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#winner');

    // page swap
    // $('.page').addClass('d-none');
    $('.winner-form-container').remove();
    $('.winner-box.after').removeClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-winner').modal('show');
});

$('#form-contact').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#kontakt');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-contact').modal('show');
});

$('#form-before').on('submit-success', function(event, data) {
    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#modal-form-before').modal('show');
});

$('#start-reminder-form').on('submit-success', function(event, data) {
    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#start-reminder-form').addClass('d-none');
    $('#modal-form-after .start-reminder-form-info').addClass('d-none');
    $('#modal-form-after .page-thank-you').removeClass('d-none');
    $('#modal-form-after .page-thank-you').css('display', 'block');
});